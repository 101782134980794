/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Slider from 'react-slick';
import { analyticsViewPromotion, analyticsSelectPromotion } from 'utils/analyticsUtils';
import Link from './Link';

function CarouselCustom({ items, type, className }) {
  const [index, setIndex] = useState(0);
  const [showedItems, setShowedItems] = useState([]);
  const [availableCampaigns, setAvailableCampaigns] = useState([]);
  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };
  let dragging = false; //for slick carousel

  useEffect(() => {
    campaignAvailabilityHandler();
    window?.dataLayer?.push({
      cd_pageType: 'anasayfa',
    });
  }, []);

  function checkTargetSlider(x) {
    return x.node.TargetSlider === type;
  }
  const filteredItems = availableCampaigns?.filter(checkTargetSlider);
  const sortedItems = filteredItems?.sort((a, b) => (a?.node?.DisplayOrder > b?.node?.DisplayOrder ? 1 : -1));
  if (!items) {
    return null;
  }

  function getCurrentDate() {
    const current = new Date();
    // eslint-disable-next-line prefer-template
    const date = current.getFullYear() + '-' + ('0' + (current.getMonth()+1)).slice(-2) + '-' + ('0' + current.getDate()).slice(-2);
    let time = current.getHours() + ':' + current.getMinutes() + ':' + current.getSeconds();
    const datetime = {date, time};
    return datetime;
  }

  function campaignAvailabilityHandler(){
    const currentDateTime = getCurrentDate();
    let campaigns = [];
    for(let i=0;i<items.length;i++){
      if(currentDateTime.date >= items?.[i].node?.StartDate && currentDateTime.date <= items?.[i].node?.EndDate){
        if(currentDateTime.time >= items?.[i]?.node?.DisplayStartTime && currentDateTime.time <= items?.[i]?.node?.DisplayEndTime){
          // Kampanya bu tarih ve saat aralığında mevcut
          campaigns.push(items?.[i]);
        } else if (!(items?.[i]?.node?.DisplayStartTime) && !(items?.[i]?.node?.DisplayEndTime)){
          // Kampanya bu tarih aralığında mevcut(saat bilgisi mevcut değil)
          campaigns.push(items?.[i]);
        }
      }
    }
    setAvailableCampaigns(campaigns);
  }

  useEffect(() => {
    const currentItem = sortedItems?.[0]?.node;
    if (currentItem && !showedItems.some((item) => [currentItem?.id, currentItem?.Id].includes(item))) {
      setShowedItems([...showedItems, currentItem?.id || currentItem?.Id]);
      analyticsViewPromotion({ currentItem: currentItem, page: 'HERO_BANNER' });
    }
  }, [sortedItems]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'ondemand',
    autoplay: true,
    autoplaySpeed: '50',
    beforeChange: (curr, next) => {
      dragging = true;
      let currentItem = sortedItems?.length > Number(next) && sortedItems[Number(next)];

      currentItem = currentItem?.node;

      if (currentItem && !showedItems.some((item) => [currentItem?.id, currentItem?.Id].includes(item))) {
        setShowedItems([...showedItems, currentItem?.id || currentItem?.Id]);
        analyticsViewPromotion({ currentItem, page: 'HERO_BANNER' });
      }
    },
    afterChange: () => (dragging = false),
  };

  return (
    <div className={`carousel-custom ${className}`}>
      <Slider activeIndex={index} onSelect={handleSelect} {...settings}>
        {sortedItems?.map((item) => (
          <div key={item?.node?.Title}>
            <Link
              to={item?.node?.ExternalUrl ? item?.node?.ExternalUrl : `/${item?.node?.Slug}`}
              onClick={(e) => {
                dragging && e.preventDefault();
                if (!dragging) {
                  analyticsSelectPromotion({ item: item?.node, page: 'HERO_BANNER' });
                }
              }}
            >
              <img
                className="d-block w-100 banner-image"
                src={item?.node?.Banner[0]?.url}
                width="100%"
                height="auto"
                alt={item?.node?.Title}
              />
            </Link>
            {type !== 'Main' && <div className="carousel-title">{item?.node?.Brand?.Name}</div>}
          </div>
        ))}
      </Slider>
    </div>
  );
}

CarouselCustom.propTypes = {
  items: PropTypes.array,
  type: PropTypes.string, // Main, FirstLevel, SecondLevel
  className: PropTypes.string,
};

// CarouselCustom.defaultProps = {}

export default CarouselCustom;
