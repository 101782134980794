/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo } from 'react';
import Modal from 'react-bootstrap/Modal';
import PropTypes from 'prop-types';
import Button from '../Button';
import './modal.scss';

function CustomModal({
  children,
  contentClassName,
  show,
  closeButton,
  onPressCloseButton,
  title,
  onPressButton,
  onPressSecondaryButton,
  buttonDisabled,
  secondaryButtonDisabled,
  buttonTitle,
  secondaryButtonTitle,
  backdrop,
  customClass,
  buttonRadius,
  buttonBlock,
  secondaryButtonVariant,
  buttonSize,
  buttonSticky,
  buttonStickyNotBorder,
  iconAlert,
  titleUnderlined,
  isLeftTitle,
  isRowButton,
}) {
  return (
    <Modal
      show={show}
      contentClassName={contentClassName}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      backdrop={backdrop || 'static'}
      className={customClass}
    >
      <Modal.Header>
        {iconAlert && (
          <div className="modal-icon">
            <i className="icon-alert-filled" />
          </div>
        )}
        {title && <Modal.Title className={isLeftTitle ? 'left-title' : ''}>{title}</Modal.Title>}
        {titleUnderlined && <div className="divider" />}
        {closeButton && (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className="close" onClick={() => onPressCloseButton()}>
            <i className="icon-Close-3" />
          </div>
        )}
      </Modal.Header>

      <Modal.Body className={buttonSticky ? 'sticky' : buttonStickyNotBorder && 'stickyNotBorder'}>
        {children}
      </Modal.Body>
      {(secondaryButtonTitle || buttonTitle) && (
        <Modal.Footer
          className={[
            buttonBlock && 'block',
            buttonSticky && 'sticky',
            buttonStickyNotBorder && 'stickyNotBorder',
            isRowButton && 'rowButton',
          ].join(' ')}
        >
          <div className={buttonSticky ? 'sticky' : buttonStickyNotBorder && 'stickyNotBorder'}>
            {!!secondaryButtonTitle && (
              <Button
                disabled={secondaryButtonDisabled}
                onPress={() => {
                  if (onPressSecondaryButton) onPressSecondaryButton();
                }}
                variant={secondaryButtonVariant}
                size={buttonSize}
                radius={buttonRadius}
              >
                {secondaryButtonTitle}
              </Button>
            )}

            {!!buttonTitle && (
              <Button
                disabled={buttonDisabled}
                onPress={() => {
                  if (onPressButton) onPressButton();
                }}
                size={buttonSize}
                radius={buttonRadius}
              >
                {buttonTitle}
              </Button>
            )}
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
}

CustomModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onPressButton: PropTypes.func,
  onPressSecondaryButton: PropTypes.func,
  buttonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondaryButtonTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  contentClassName: PropTypes.string,
  secondaryButtonVariant: PropTypes.string, // 'white' || 'danger-link',
  buttonDisabled: PropTypes.bool,
  secondaryButtonDisabled: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  customClass: PropTypes.string,
  buttonBlock: PropTypes.bool, // block
  buttonRadius: PropTypes.string, // low 4px , high default 10px.
  buttonSize: PropTypes.string, // sm 38px, md default 44px.
  buttonSticky: PropTypes.bool,
  buttonStickyNotBorder: PropTypes.bool,
  iconAlert: PropTypes.bool,
  titleUnderlined: PropTypes.bool,
  closeButton: PropTypes.bool,
  isLeftTitle: PropTypes.bool,
  isRowButton: PropTypes.bool,
  onPressCloseButton: PropTypes.func,
};

CustomModal.defaultProps = {
  buttonBlock: false,
  buttonRadius: 'high',
  buttonSize: 'md',
  secondaryButtonVariant: 'white',
};

export default memo(CustomModal);
