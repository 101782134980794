/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Modal from '../UI/Modal';
import Button from '../UI/Button';
import './index.scss';
import Link from '../Link';

const CookiePolicyBanner = forwardRef(function CookiePolicyBanner(props, ref) {
  useImperativeHandle(ref, () => ({ showCookieModal: () => setIsShowModal(true) }), []);

  const [isShowBanner, setIsShowBanner] = useState(false);
  const [isShowModal, setIsShowModal] = useState(null);
  const [activeRow, setActiveRow] = useState(0);

  const [cookieItems, setCookieItems] = useState([
    {
      isRequired: true,
      isActive: true,
      title: 'Zorunlu Tanımlama Bilgileri',
      desc:
        'Bu tanımlama bilgileri, web sitesinin çalışması için gereklidir ve sistemlerimizde kapatılamaz. Bunlar genellikle yalnızca senin işlemlerini gerçekleştirmek için ayarlanmıştır. Bu işlemler, gizlilik tercihlerini belirlemek, oturum açmak veya form doldurmak gibi hizmet taleplerini içerir. Tarayıcını, bu tanımlama bilgilerini engelleyecek veya bunlar hakkında seni uyaracak şekilde ayarlayabilirsin ancak bu durumda sitenin bazı bölümleri çalışmayabilir.',
    },
    {
      isActive: false,
      category: 'analytics_storage',
      title: 'Performans Tanımlama Bilgileri',
      desc:
        'Bu tanımlama bilgileri, sitemizin performansını ölçebilmemiz ve iyileştirebilmemiz için sitenin ziyaret edilme sayısını ve trafik kaynaklarını sayabilmemizi sağlar. Hangi sayfaların en fazla ve en az ziyaret edildiğini ve ziyaretçilerin sitede nasıl gezindiklerini öğrenmemize yardımcı olurlar. Bu tanımlama bilgilerinin topladığı tüm bilgiler derlenir ve bu nedenle anonimdir. Bu tanımlama bilgilerine izin vermezsen sitemizi ne zaman ziyaret ettiğini bilemeyiz.',
    },
    {
      isActive: false,
      category: 'functionality_storage',
      title: 'İşlevsel Tanımlama Bilgileri',
      desc:
        'Bu tanımlama bilgileri, videolar ile canlı sohbet gibi gelişmiş işlevler ve kişiselleştirme olanağı sunabilmemizi sağlar. Bunlar, bizim tarafımızdan veya sayfalarımızda hizmetlerinden faydalandığımız üçüncü taraf sağlayıcılarca ayarlanabilir. Bu tanımlama bilgilerine izin vermezsen bu işlevlerden tümü veya bazıları doğru şekilde çalışmayabilir.',
    },
    {
      isActive: false,
      category: 'ad_storage',
      title: 'Hedefleme Amaçlı Tanımlama Bilgileri',
      desc:
        'Bu tanımlama bilgileri, sitemizde reklam ortaklarımız tarafından ayarlanır. Bunlar, ilgili şirketler tarafından ilgi alanları profilini oluşturmak ve diğer sitelerde alakalı reklamlar göstermek için kullanılabilir. Benzersiz olarak tarayıcını ve cihazını belirleyerek çalışırlar. Bu tanımlama bilgilerine izin vermezsen farklı sitelerde sana özel reklam deneyimi sunamayız.',
    },
  ]);

  useEffect(() => {
    setTimeout(() => {
      setIsShowBanner(localStorage.isAcceptedCookiePolicySettings !== 'true');
    }, 1000);
  }, []);

  useEffect(() => {
    if (isShowModal === null) return;
    setCookieItems(
      cookieItems.map((item) => ({
        ...item,
        isActive: item.isRequired ? true : localStorage[item.category] === 'true',
      })),
    );
    if (!isShowModal) {
      window?.dataLayer?.push({
        event: 'consent_settings',
        ad_storage: localStorage?.ad_storage === 'true',
        analytics_storage: localStorage?.analytics_storage === 'true',
        functionality_storage: localStorage?.functionality_storage === 'true',
      });
    }
  }, [isShowModal]);

  const saveCookieSettings = () => {
    localStorage.setItem('isAcceptedCookiePolicySettings', 'true');
    setIsShowBanner(false);
  };

  const saveAllCookieCategories = () => {
    cookieItems.forEach(({ category }) => {
      if (category) {
        localStorage.setItem(category, 'true');
      }
    });
    window?.dataLayer?.push({
      event: 'consent_settings',
      ad_storage: true,
      analytics_storage: true,
      functionality_storage: true,
    });
  };

  return (
    <>
      <Modal
        show={!!isShowModal}
        contentClassName="modal-content-custom"
        buttonTitle="Çerez Ayarlarını Kaydet"
        isLeftTitle
        onPressButton={() => {
          cookieItems.forEach(({ isActive, isRequired, category }) => {
            if (isRequired) return;
            localStorage.setItem(category, isActive ? 'true' : 'false');
          });
          setIsShowModal(false);
          saveCookieSettings();
        }}
        onPressSecondaryButton={() => setIsShowModal(false)}
        secondaryButtonTitle="Kapat"
        title="Çerez Tercihleri"
        buttonSticky
        isRowButton
      >
        <div className="cookie-modal">
          <p className="cookie-modal-desc">
            Daha iyi bir kullanıcı deneyimi sunabilmek adına internet sitemizde çerez kullanılmaktadır. Çerezler
            hakkında daha fazla bilgi almak için veya herhangi bir zamanda çerez tercihlerini değiştirmek için{' '}
            <Link to="/cerez-aydinlatma-metni" target="_blank" rel="noopener norefferer">
              Çerez Aydınlatma Metni
            </Link>
            ’ni ziyaret edebilirsin.
          </p>
          <div className="cookies">
            {cookieItems.map((item, index) => (
              <div key={item.title} className={index === activeRow ? 'cookies-item active' : 'cookies-item'}>
                <div className="cookies-item-title">
                  <button
                    type="button"
                    className="cookies-item-title-button"
                    onClick={() => {
                      setActiveRow(index === activeRow ? -1 : index);
                    }}
                  >
                    <i className="icon-Arrow_Simple_down" />
                    <span className="cookies-item-title-button-title">{item.title}</span>
                  </button>
                  <button
                    disabled={item.isRequired}
                    type="button"
                    className={item.isActive ? 'switch active' : 'switch'}
                    onClick={() => {
                      const newCookieItems = [...cookieItems];
                      newCookieItems[index] = {
                        ...newCookieItems[index],
                        isActive: !newCookieItems[index].isActive,
                      };
                      setCookieItems(newCookieItems);
                    }}
                  />
                </div>
                <div className="cookies-item-content">{item.desc}</div>
              </div>
            ))}
          </div>
        </div>
      </Modal>

      <div className={`cookie-container ${isShowBanner ? 'show' : ''}`}>
        <div className="cookie-col">
          <div className="title">Çerez Bildirimi</div>
        </div>
        <div className="cookie-col">
          <div className="text">
            İnternet sitemizdeki bazı çerezler reklam ve pazarlama faaliyetlerinin yürütülmesi, sitemizin daha işlevsel
            kılınması, internet sitemizi kullanımına yönelik ölçümlerin yapılması ve kişiselleştirilmesi çalışmakta olup
            bu çerezlerle kişisel verilerinin işlenmesi açık rızana tabidir. Açık rızanı "Kabul Ediyorum" ile sunabilir
            ya da{' '}
            <span role="button" onClick={() => setIsShowModal(true)}>
              Çerez Tercihlerinden
            </span>{' '}
            seçeneklerini kaydedebilirsin. Çerezlerle işlenecek olan kişisel verilerine yönelik aydınlatma metnini
            okumak için{' '}
            <Link to="/cerez-aydinlatma-metni" target="_blank" rel="noopener norefferer">
              tıklayın
            </Link>
            .
          </div>
        </div>
        <div className="cookie-col flex-end">
          <Button
            size="sm"
            onPress={() => {
              saveAllCookieCategories();
              saveCookieSettings();
            }}
          >
            Kabul Ediyorum
          </Button>
          <Button
            size="sm"
            variant="white"
            onPress={() => {
              saveCookieSettings();
              setIsShowBanner(false);
            }}
          >
            Kabul Etmiyorum
          </Button>
          <Button
            size="sm"
            className="button-cerez"
            variant="white"
            onPress={() => {
              setIsShowModal(true);
            }}
          >
            Çerez Tercihleri
          </Button>
        </div>
      </div>
    </>
  );
});

export default CookiePolicyBanner;
