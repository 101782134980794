import React, { useEffect, useImperativeHandle, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Container } from 'react-bootstrap';
import { I18nextProvider } from 'react-i18next';
import { ApolloProvider } from '@apollo/client';
import i18n from '../locales/tr/i18n';
import { apolloClient } from '../apollo/client';
import { redirectToApp } from '../utils/request';
import DownloadBanner from './downloadBanner';
import BreadCrumb from './BreadCrumb';
import Footer from './navigation/Footer';
import Header from './header';
import Sidebar from './sidebar';
import StickyInfoBox from './StickyInfoBox';
import AppDownloadCard from './AppDownloadCard';
import './layout.scss';
import BasketButton from './StickyBasket/basketButton';
import CookiePolicyBanner from './CookiePolicyBanner';

function Layout({
  children,
  sidebar,
  brandName,
  productName,
  brandUrl,
  header,
  home,
  isNotFound,
  searchEnabled,
  searchData,
  setSearchResult,
  preferApp,
  location,
  hasBreadCrumb,
  hasProduct,
  layoutRef,
}) {
  const [collapse, setCollapse] = useState(false);
  const path = location?.pathname;
  const isCampaignPage = path?.includes('/kampanyalar');
  const isCampaignDetailPage = isCampaignPage && path?.split('/')[2] && path?.split('/')[2] !== '';
  const hasBreadcrumb = !(isNotFound || home || hasBreadCrumb);
  const basketButtonClassName = hasProduct ? 'sticky-basket sticky-basket--hasProduct' : 'sticky-basket';

  const ref = useRef(null);

  useImperativeHandle(layoutRef, () => ({ showCookieModal: ref.current.showCookieModal }), []);

  useLayoutEffect(() => {
    if (localStorage?.ad_storage === 'true') {
      const insiderScript = document.createElement('script');
      insiderScript.src = `https://${process.env.INSIDER_PARTNER_NAME}.api.useinsider.com/ins.js?id=10006073`;
      document.getElementsByTagName('head')[0].append(insiderScript);
    }
  }, []);

  useEffect(() => {
    if (preferApp) redirectToApp();
  }, []);

  return (
    <>
      <ApolloProvider client={apolloClient}>
        <I18nextProvider i18n={i18n}>
          <div className={basketButtonClassName}>
            <BasketButton />
          </div>
          <DownloadBanner />
          {sidebar && !isNotFound ? (
            <div className="min-50">
              {header ? (
                <Header
                  setSearchResult={setSearchResult}
                  home={home}
                  searchEnabled={searchEnabled}
                  searchData={searchData}
                  setCollapse={setCollapse}
                  collapse={collapse}
                />
              ) : null}
              <Container className={`content-container ${collapse ? 'overflow' : ''}`}>
                <div className="min-50">
                  <Row>
                    <Col xs={{ order: '2', span: 12 }} lg={{ order: '1', span: 3 }}>
                      <Sidebar />
                    </Col>
                    <Col xs={{ order: '1', span: 12 }} lg={{ order: '1', span: 9 }}>
                      {children}
                    </Col>
                  </Row>
                </div>
              </Container>
            </div>
          ) : (
            <div className="min-50">
              {header ? (
                <Header
                  setSearchResult={setSearchResult}
                  brandName={brandName}
                  searchEnabled={searchEnabled}
                  searchData={searchData}
                  setCollapse={setCollapse}
                  collapse={collapse}
                />
              ) : null}
              <Container className={`content-container${collapse ? 'overflow' : ''}`}>
                <>
                  {!hasBreadcrumb ? null : (
                    <BreadCrumb
                      urlPath={path}
                      isCampaignPage={isCampaignPage}
                      isCampaignDetailPage={isCampaignDetailPage}
                      productName={productName}
                      brandName={brandName}
                      brandUrl={brandUrl}
                    />
                  )}
                  {children}
                </>
              </Container>
            </div>
          )}

          <Footer collapse={collapse} />
          <StickyInfoBox localStorageKey="appDpwnloadInfoBox-www">
            <AppDownloadCard />
          </StickyInfoBox>
        </I18nextProvider>
      </ApolloProvider>
      <CookiePolicyBanner ref={ref} />
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  sidebar: PropTypes.bool,
  header: PropTypes.bool,
  brandName: PropTypes.string,
  productName: PropTypes.string,
  brandUrl: PropTypes.string,
  hasBreadCrumb: PropTypes.bool,
  hasProduct: PropTypes.bool,
  layoutRef: PropTypes.any,
  isNotFound: PropTypes.bool,
  location: PropTypes.object,
  home: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  searchData: PropTypes.any,
  setSearchResult: PropTypes.any,
  preferApp: PropTypes.bool,
};

Layout.defaultProps = {
  sidebar: true,
  header: true,
  isNotFound: false,
};

export default Layout;
