/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable no-return-assign */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import { analyticsFooterClick } from 'utils/analyticsUtils';
import Link from '@components/Link';

function FooterAccordion({ title, data, hideIcon, activeType, linkAdditionalText, staticItem }) {
  return (
    <Accordion className="footer-accordion">
      <CustomToggle eventKey="0" hideIcon={hideIcon}>
        {title}
      </CustomToggle>
      <Accordion.Collapse eventKey="0">
        <ul className="accordion-item-list">
          {staticItem}
          {data?.map((item) => (
            <li
              className="accordion-item type"
              // eslint-disable-next-line prefer-template
              key={'accordion-item-' + Math.floor(Math.random() * 10000) + 1}
            >
              {item?.type === 'link' ? (
                <Link
                  to={item?.link}
                  onClick={() => {
                    analyticsFooterClick({
                      category: title,
                      element: item?.title,
                      path: item?.link,
                    });
                  }}
                >
                  {item.title}
                </Link>
              ) : (
                <a
                  className="accordion-item-link"
                  href={item?.node?.[activeType] || item?.link}
                  target={item?.node?.target || item?.target}
                  rel={item?.rel}
                  onClick={() => {
                    analyticsFooterClick({
                      category: title,
                      element: item?.node?.Name || item?.title,
                      path: item?.node?.[activeType] || item?.link,
                    });
                  }}
                  // onClick={item?.onClick ? () => (window.location.href = item?.onClick) : null}
                >
                  <span>
                    {item?.node?.Name || item?.title} {linkAdditionalText}
                  </span>
                </a>
              )}
            </li>
          ))}
        </ul>
      </Accordion.Collapse>
    </Accordion>
  );
}

function CustomToggle({ children, eventKey, hideIcon }) {
  const [active, setActive] = useState(false);
  const decoratedOnClick = useAccordionToggle(eventKey, () => {
    setActive(!active);
  });

  return (
    <a
      role="button"
      onClick={decoratedOnClick}
      className={`btn-accordion ${active ? 'active' : ''} ${hideIcon ? 'hide-icon' : ''}`}
    >
      {children}
    </a>
  );
}

FooterAccordion.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array,
  hideIcon: PropTypes.bool,
  activeType: PropTypes.string,
  linkAdditionalText: PropTypes.string,
  staticItem: PropTypes.any,
};

CustomToggle.propTypes = {
  children: PropTypes.string,
  eventKey: PropTypes.string,
  hideIcon: PropTypes.bool,
};

export default FooterAccordion;
