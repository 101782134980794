import React from 'react';
import PropTypes from 'prop-types';
import Numeral from 'numeral';

Numeral.register('locale', 'tr', {
  delimiters: {
    thousands: '.',
    decimal: ',',
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't',
  },
  currency: {
    symbol: 'TL',
  },
});

Numeral.locale('tr');

function CurrencyText(props) {
  const { style, children, enableTheme, className } = props;
  // const { ...rest } = style;

  if (children || children === 0) {
    const convertedValue = Numeral(children).format('0,0.00 $');
    return (
      <span className={`currency-text ${enableTheme && 'theme-color'} ${className}`} style={style}>
        {convertedValue}
      </span>
    );
  }
  return null;
}

CurrencyText.defaultProps = {
  enableTheme: false,
};

CurrencyText.propTypes = {
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.array]),
  enableTheme: PropTypes.bool,
  className: PropTypes.string,
};

export default CurrencyText;
