import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { analyticsMenuTap } from 'utils/analyticsUtils';
import { GET_CART } from '../../apollo/queries/cart';
import { getCartListLength } from '../../apollo/LocalData/Operators/CartOperator';
import CurrencyText from '../currencyText';
import './basketButton.scss';
import Link from '../Link';

const BasketButton = () => {
  const [itemAmount, setItemAmount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [cart, setCart] = useState();
  const [cartLoading, setCartLoading] = useState(true);

  const [getCart] = useLazyQuery(GET_CART, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (value) => {
      const itemAmountVal = getCartListLength(value?.cart?.cart);
      const totalPriceVal = itemAmountVal && value?.cart?.totalPrice;
      setTotalPrice(totalPriceVal);
      setItemAmount(itemAmountVal);
      setCartLoading(false);
      setCart(value);
    },
    onError: (error) => {
      setCartLoading(false);
      console.log('Sepet bilgileri çekilemedi' || error);
    },
  });

  useEffect(() => {
    getCart();
  }, []);
  if (!cartLoading && cart) {
    return (
      <Link to="/a/basket">
        <div className="sticky-basket-content">
          <div className="left-side">
            {itemAmount ? <span className="basket-amount-badge">{itemAmount}</span> : null}
            <i className="icon-Basket" />
          </div>
          <CurrencyText className="basket-text">{totalPrice}</CurrencyText>
        </div>
      </Link>
    );
  }

  return (
    <Link
      to="/a/"
      onClick={() => {
        analyticsMenuTap({
          screen: '/a/',
          element: 'Cart',
          basketValue: 0,
        });
      }}
    >
      <div className="sticky-basket-content">
        <div className="left-side">
          {itemAmount ? <span className="basket-amount-badge">{itemAmount}</span> : null}
          <i className="icon-Basket" />
        </div>
        <span className="p-3 text-nowrap">Sepetim</span>
      </div>
    </Link>
  );
};

BasketButton.propTypes = {};

export default BasketButton;
