/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
/* eslint-disable no-return-assign */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'react-bootstrap';
import { each } from 'lodash';
import { useWindowSize } from 'utils/windowSize';
import { brandInfo } from 'utils/campaignUtils';
import Link from './Link';

const SearchInput = ({ data, home, parentCallBack, onIconClick }) => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState('');
  const [productSearchList, setProductSearchList] = useState([]);
  const inputRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [inputWidth, setInputWith] = useState(null);
  const [searchIndex, setSearchIndex] = useState(0);
  const scrollRef = useRef(null);
  const [searchActive, setSearchActive] = useState(false);
  const windowSize = useWindowSize();
  const isMobileSize = windowSize.width <= 768;

  useEffect(() => {
    setInputWith(inputRef?.current?.clientWidth);
  }, []);

  function convertBrandIdToBrandName(brandID) {
    const obj = brandInfo?.find((x) => x.brandId === brandID);
    return obj && obj.brandName;
  }

  function englishCharConvertToTurkishChar(incomingTextWord) {
    const incomingTextCharArray = incomingTextWord.split('');
    let incomingText = '';
    each(incomingTextCharArray, (item) => {
      if (item === 'i' || item === 'I') {
        incomingText += 'ı';
      } else if (item === 'u' || item === 'U') {
        incomingText += 'ü';
      } else if (item === 'g' || item === 'G') {
        incomingText += 'ğ';
      } else if (item === 'o' || item === 'O') {
        incomingText += 'ö';
      } else if (item === 'c' || item === 'C') {
        incomingText += 'ç';
      } else if (item === 's' || item === 'S') {
        incomingText += 'ş';
      } else {
        incomingText += item;
      }
    });
    return incomingText.toUpperCase();
  }

  function allProducts() {
    let allProductData = [];
    let subCategories;
    let i;
    let e;

    for (i = 0; i < data?.length; i += 1) {
      subCategories = data[i]?.subcategories;

      for (e = 0; e < subCategories?.length; e += 1) {
        allProductData = allProductData?.concat(subCategories[e]?.products);
      }
    }
    return allProductData;
  }

  function concatAllBrandProducts() {
    const allProductData = [];
    if (!Array.isArray(data)) {
      return allProductData;
    }
    data.forEach(({ id = '', products = [] }) => {
      products.forEach(({ subcategories = [] }) => {
        subcategories.forEach((subcategory = {}) => {
          subcategory.products?.forEach((item) => {
            if (!allProductData.find((allDataItem) => allDataItem.name === item.name)) {
              allProductData.push({ name: item.name || '', brandId: id });
            }
          });
        });
      });
    });

    return allProductData;
  }
  const searchProduct = (searchTextVal) => {
    setSearchIndex(0);
    setText(searchTextVal);
    if (searchTextVal?.length >= 3) {
      setShow(true);
      const searchList = [];
      const searchText = searchTextVal.toUpperCase();
      const searchTextWithTurkishCharacter = englishCharConvertToTurkishChar(searchTextVal);

      const products = home ? concatAllBrandProducts() : allProducts();
      each(products, (item) => {
        const productName = item?.name?.toUpperCase();
        if (productName?.indexOf(searchText) >= 0 || productName?.indexOf(searchTextWithTurkishCharacter) >= 0) {
          searchList.push(item);
        }
      });
      setProductSearchList(searchList);
      parentCallBack?.(searchList);
    } else {
      setShow(false);
      setProductSearchList([]);
      parentCallBack?.([]);
    }
  };

  function handleKeyDown(key) {
    if (key.keyCode === 13) {
      // `/${convertBrandIdToBrandName(productSearchList[searchIndex]?.brandId)}`
      window.location.href = `/${convertBrandIdToBrandName(productSearchList[searchIndex]?.brandId)}`;
    }
    if (key.keyCode === 38) {
      if (searchIndex !== 0) {
        setSearchIndex(searchIndex - 1);
        if (
          scrollRef?.current?.children?.[searchIndex - 1]?.offsetTop <
          scrollRef?.current?.scrollHeight - scrollRef?.current?.offsetHeight
        )
          scrollRef.current.scrollTop -= scrollRef?.current?.children?.[searchIndex - 1]?.offsetHeight;
      }
    }
    if (key.keyCode === 40) {
      if (searchIndex !== productSearchList.length - 1) {
        setSearchIndex(searchIndex + 1);
        if (
          scrollRef?.current?.offsetHeight <
          scrollRef?.current?.children?.[searchIndex + 1]?.offsetTop +
            scrollRef?.current?.children?.[searchIndex + 1]?.offsetHeight
        ) {
          scrollRef.current.scrollTop += scrollRef?.current?.children?.[searchIndex + 1]?.offsetHeight;
        }
      }
    }
  }

  return (
    <div className={`search-input-wrapper  ${searchActive ? 'search-active' : ''}`}>
      <input
        placeholder="Bir ürün ya da restoran arayın"
        value={text}
        id="search-input"
        onChange={(e) => searchProduct(e?.target?.value)}
        type="text"
        className="form-control search-input"
        ref={inputRef}
        onKeyDown={(key) => home && handleKeyDown(key)}
      />
      <Button
        variant="transparent"
        className="btn-icon"
        onClick={() => {
          if (searchActive) {
            searchProduct('');
          }
          setSearchActive(!searchActive);
          if (!searchActive) onIconClick();
        }}
      >
        <i
          className={productSearchList?.length > 0 || (isMobileSize && searchActive) ? 'icon-Close-2' : 'icon-search'}
        />
      </Button>
      {home && productSearchList?.length > 0 && (
        <Dropdown>
          <Dropdown.Menu
            ref={scrollRef}
            show={show}
            style={{ display: show && 'block', minWidth: 315, maxHeight: 400, overflow: 'auto' }}
          >
            {productSearchList?.map((item, index) => (
              <Dropdown.Item
                key={`${item?.brandId}-${item?.name}`}
                className={index === searchIndex && 'dropdown-selected-item'}
              >
                <Link style={{ color: 'black' }} to={`/${convertBrandIdToBrandName(item?.brandId)}`}>
                  <span>{item?.name}</span>
                </Link>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  data: PropTypes.array,
  home: PropTypes.bool,
  parentCallBack: PropTypes.func,
  onIconClick: PropTypes.func,
};

export default SearchInput;
