import React from 'react';
import images from '../../images/images';
import './appDownloadCard.scss';

function AppDownloadCard() {
  return (
    <div className="qr-code">
      <div className="qr-code-inner">
        <div className="qr-code-image">
          <img src={images.appDownloadQrCode} alt="Tıkla Gelsin QR Code" width="108" height="108" />
        </div>
        <div className="qr-code-right">
          <p className="qr-code-inner-text">
            Uygulamayı hemen indir <strong>avantajları</strong> kaçırma!
          </p>
          <div className="qr-code-apps">
            <a target="_system" href="https://play.google.com/store/apps/details?id=com.ataexpress.tiklagelsin">
              <img src={images.googlePlayStore} alt="Tıkla Gelsin App Android" width="90" height="36" />
            </a>
            <a target="_system" href="https://apps.apple.com/tr/app/t%C4%B1kla-gelsin/id1534477610?l=tr">
              <img src={images.appleStore} alt="Tıkla Gelsin App iOS" width="90" height="36" />
            </a>
          </div>
        </div>
      </div>
      <div className="qr-code-bottom-text">Telefonunuzun kamerasını kullanarak qr kodu okutabilirsiniz.</div>
    </div>
  );
}

export default AppDownloadCard;
