const brandInfo = [
  { brandId: '3f4d0e4f-c4f5-e211-bc3c-005056af03f4', brandName: 'usta-donerci' },
  { brandId: '394d0e4f-c4f5-e211-bc3c-005056af03f4', brandName: 'popeyes' },
  { brandId: '354d0e4f-c4f5-e211-bc3c-005056af03f4', brandName: 'burger-king' },
  { brandId: '5edb25cd-05be-e711-9419-0050568045bb', brandName: 'amasya-et-urunleri' },
  { brandId: '374d0e4f-c4f5-e211-bc3c-005056af03f4', brandName: 'sbarro' },
  { brandId: '3b4d0e4f-c4f5-e211-bc3c-005056af03f4', brandName: 'arbys' },
  { brandId: 'ea553690-695b-e911-941a-0050568045bb', brandName: 'usta-pideci' },
  { brandId: 'd3b491db-de22-ed11-9413-0050568036f6', brandName: 'subway' },
];

module.exports = brandInfo;
