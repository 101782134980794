/**
 *
 * home queries
 *
 */

import { gql } from '@apollo/client';

export const TOGGLE = gql`
  query TOGGLE {
    isTiklaAl
    theme
  }
`;

export const GET_BRANDS = gql`
  query GET_BRANDS($addressId: String, $lat: String, $lng: String) {
    brands(addressId: $addressId, lat: $lat, lng: $lng) {
      name
      value
      description
      isAvailable
      isTiklaGelsin
      isTiklaAl
      isGelAl
      basketCount
      brandColor: BrandColor
      startOfWorkingHour: StartOfWorkingHour
      endOfWorkingHour: EndOfWorkingHour
      showMessage
      media {
        path
      }
      splash {
        path
      }
      restaurants {
        restaurantID
        lat
        lng
        isClosed
        name
        brandName
        brandImage
        brandID
        _id
        status
        isDeleted
        geometry {
          type
          coordinates
        }
      }
    }
  }
`;

export const GET_MY_ADDRESSES = gql`
  query GET_MY_ADDRESSES {
    addresses {
      addressID
      userID
      cityName
      countyName
      districtName
      districtID
      roadID
      roadName
      streetName
      streetID
      addressName
      description
      isApproved
      status
      title
      isSelected
      restaurantIds
      telephone
      addressType
      cityID
      cityName
      countyID
      countyName
      flat
      flatNo
      room
      location {
        lng
        lat
      }
    }
  }
`;
