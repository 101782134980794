import React from 'react';
// import PropTypes from "prop-types"
import { StaticQuery, graphql } from 'gatsby';
// import { Image } from 'react-bootstrap';
// import tgLogoRed from '@images/tgLogoRed.png';
import Carousel from './carousel';
import LoginCard from './loginCard';

const query = graphql`
  query {
    allStrapiLandingPage {
      edges {
        node {
          id
          Slug
          Banner {
            url
          }
          DisplayOrder
          Title
          TargetSlider
          Brand {
            Name
            Splash {
              publicURL: url
            }
            Logo {
              publicURL: url
            }
          }
          StartDate
          EndDate
          DisplayStartTime
          DisplayEndTime
          ExternalUrl
          ProductList {
            ShortText
            Product {
              Name
              CrmId
              Image {
                publicURL: url
              }
              BrandCrmId
            }
          }
        }
      }
    }
  }
`;

const Sidebar = () => (
  <div className="sidebar">
    <LoginCard />
    <StaticQuery
      query={query}
      render={(data) => (
        <>
          <Carousel className="carousel-brands" type="FirstLevel" items={data?.allStrapiLandingPage?.edges} />
          <Carousel className="carousel-brands" type="SecondLevel" items={data?.allStrapiLandingPage?.edges} />
        </>
      )}
    />
  </div>
);

Sidebar.propTypes = {};

Sidebar.defaultProps = {};

export default Sidebar;
