import React from 'react';
import SmartBanner from 'react-smartbanner';
import { isIOS, isMobile, isSafari } from 'react-device-detect';
import { Helmet } from 'react-helmet';
import 'react-smartbanner/dist/main.css';
import './banner.scss';

export default function DownloadBanner() {
  // eslint-disable-next-line no-nested-ternary
  const forceType = isMobile && (isIOS ? 'ios' : 'android');
  return (
    <div>
      <Helmet>
        <meta name="apple-itunes-app" content="app-id=1534477610" />
        <meta name="google-play-app" content="app-id=com.ataexpress.tiklagelsin" />
      </Helmet>

      {!isSafari && forceType && (
        <SmartBanner
          force={forceType}
          title="Tıkla Gelsin"
          author=""
          price={{
            ios: 'Ücretsiz',
            android: 'Ücretsiz',
            windows: 'Ücretsiz',
            kindle: 'Ücretsiz',
          }}
          storeText={{
            ios: 'Uygulamayı indirin.',
            android: 'Uygulamayı İndirin',
            windows: 'Uygulamayı İndirin',
            kindle: 'Uygulamayı İndirin',
          }}
          button="İNDİR"
        />
      )}
    </div>
  );
}
