import React from 'react';
import './socialButtons.scss';

const SocialButtons = () => (
  <nav className="social-button">
    <ul className="social-button-items">
      <li className="social-button-item">
        <a href="https://instagram.com/tikla.gelsin?utm_medium=copy_link" target="_blank" rel="noopener norefferer">
          <i className="icon-instagram" />
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://www.facebook.com/tiklagelsin1/about" target="_blank" rel="noopener norefferer">
          <i className="icon-facebook" />
        </a>
      </li>
      <li className="social-button-item">
        <a href="https://twitter.com/tiklagelsin_?s=21" target="_blank" rel="noopener norefferer">
          <i className="icon-twitter" />
        </a>
      </li>
    </ul>
  </nav>
);

SocialButtons.propTypes = {};

export default SocialButtons;
