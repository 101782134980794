/* eslint-disable no-console */
import { ApolloClient, InMemoryCache, from, ApolloLink, HttpLink } from '@apollo/client';
import { apolloRequest } from '../utils/request';
import { REACT_APP_API_CATALOG_URL, REACT_APP_API_USER_URL } from '../utils/paths';
import { TOGGLE } from './queries/home';

const customFetch = () => (uri, options) => apolloRequest(uri, options);

const httpLinkCatalog = new HttpLink({
  uri: REACT_APP_API_CATALOG_URL,
  fetch: customFetch(),
  useGETForQueries: false,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors);
    console.log('networkError', networkError);
  },
});

const httpLinkUser = new HttpLink({
  uri: REACT_APP_API_USER_URL,
  fetch: customFetch(),
  useGETForQueries: false,
  onError: ({ networkError, graphQLErrors }) => {
    console.log('graphQLErrors', graphQLErrors);
    console.log('networkError', networkError);
  },
});

const httpLink = ApolloLink.split((operation) => operation.getContext().apiUser, httpLinkUser, httpLinkCatalog);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const cache = new InMemoryCache();

const defaultCacheValues = {
  isTiklaAl: false,
  theme: 'red',
};

cache.writeQuery({ query: TOGGLE, data: defaultCacheValues });

export const apolloClient = new ApolloClient({
  link: from([httpLink]),
  cache,
  defaultOptions,
});
