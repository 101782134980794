import React from 'react';
import PropTypes from 'prop-types';
import '@scss/_scrollbar.scss';

export default function Scrollbar({ children, className }) {
  return (
    <div className={`scrollbar-container ${className}`}>
      <div className={`scrollbar-container-inner ${className}-inner`}>{children}</div>
    </div>
  );
}

Scrollbar.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
};
