/**
 *
 * Button
 *
 */

import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button as BootstrapButton } from 'react-bootstrap';

import '@scss/_button.scss';

function Button({ onPress, children, ...rest }) {
  return (
    <BootstrapButton {...rest} onClick={onPress}>
      {children}
    </BootstrapButton>
  );
}

Button.propTypes = {
  onPress: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default memo(Button);
