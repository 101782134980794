import React, { memo } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './breadCrumb.scss';
import Link from '../Link';
import Scrollbar from '../scrollbar';

function BreadcrumbItem({ path, name, isLastItem }) {
  if (isLastItem) {
    return <Breadcrumb.Item active>{name}</Breadcrumb.Item>;
  }

  return (
    <li className="breadcrumb-item">
      <Link to={path} title={name}>
        {name}
      </Link>
    </li>
  );
}
BreadcrumbItem.propTypes = {
  path: PropTypes.string,
  name: PropTypes.string,
  isLastItem: PropTypes.bool,
};

const capitalizePath = (str) => {
  const words = str.split('-');
  const capitalizedWords = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return capitalizedWords.join(' ');
};

function BreadCrumb({ brandName, productName, brandUrl, isCampaignDetailPage, isCampaignPage, urlPath }) {
  const pageList = [{ name: 'Anasayfa', path: '/' }];

  if (brandName) {
    pageList.push({
      name: brandName,
      path: `/${brandUrl}`,
    });
  }

  if (isCampaignPage) {
    pageList.push({
      name: 'Kampanyalar',
      path: '/kampanyalar',
    });
  }

  if (isCampaignDetailPage) {
    pageList.push({
      name: capitalizePath(urlPath?.split('/')?.[2]),
      path: urlPath,
    });
  }

  if (productName) {
    pageList.push({ name: productName });
  }
  // ld+json
  const itemList = pageList?.map((page, index) => {
    const siteBaseUrl = `${process.env.SITE_URL}`;
    const item = {
      '@type': 'ListItem',
      position: index + 1,
      name: page?.name || '',
    };
    if (page?.path !== undefined) {
      item.url = `${siteBaseUrl}${page?.path}`;
    }
    return item;
  });

  const breadCrumbSchema = {
    '@context': 'http://schema.org',
    '@type': 'BreadCrumbList',
    itemListElement: itemList,
  };

  return (
    <Scrollbar>
      <Breadcrumb className="breadcrumb-container">
        {pageList?.map((item, i) => (
          <BreadcrumbItem key={item.path} path={item.path} name={item.name} isLastItem={i === pageList?.length - 1} />
        ))}
        {breadCrumbSchema && <script type="application/ld+json">{JSON.stringify(breadCrumbSchema)}</script>}
      </Breadcrumb>
    </Scrollbar>
  );
}
BreadCrumb.propTypes = {
  brandName: PropTypes.string,
  productName: PropTypes.string,
  brandUrl: PropTypes.string,
  isCampaignDetailPage: PropTypes.bool,
  isCampaignPage: PropTypes.bool,
  urlPath: PropTypes.string,
};

export default memo(BreadCrumb);
