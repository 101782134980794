/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './stickyInfoBox.scss';

function StickyInfoBox({ placement, children, localStorageKey }) {
  const [closeStickyInfoBox, setCloseStickyInfoBox] = useState(true);

  useEffect(() => {
    if (!localStorage.getItem(`@www-${localStorageKey}`)) {
      setCloseStickyInfoBox(false);
    }
  }, []);

  function handleCloseStickyInfoBox() {
    localStorage.setItem(`@www-${localStorageKey}`, true);
    setCloseStickyInfoBox(true);
  }

  return (
    <div>
      {!closeStickyInfoBox ? (
        <div
          className={!closeStickyInfoBox ? `sticky-info-box sticky-info-box--${placement}` : 'sticky-info-box--closed'}
        >
          {children}
          <div className="sticky-info-box-close-btn" onClick={handleCloseStickyInfoBox}>
            <i className="icon-Close-3" />
          </div>
        </div>
      ) : null}
    </div>
  );
}

StickyInfoBox.defaultProps = {
  placement: 'right',
};

StickyInfoBox.propTypes = {
  children: PropTypes.object,
  placement: PropTypes.string,
  localStorageKey: PropTypes.string,
};

export default StickyInfoBox;
