import React from 'react';
import PropTypes from 'prop-types';
import { Link as GLink } from 'gatsby';

function Link(props) {
  const siteBaseUrl = process.env.SITE_URL;
  return <GLink {...props} to={`${siteBaseUrl}${props?.to}`} />;
}

Link.propTypes = {
  to: PropTypes.string,
};

export default Link;
