import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navbar, Container, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import tgLogoRed from '../images/tgLogoRed.png';
import SearchInput from './searchInput';
import BasketButton from './StickyBasket/basketButton';
import Button from './button';
import { analyticsMenuTap } from '../utils/analyticsUtils';
import Link from './Link';

const Header = ({ home, searchEnabled, searchData, setSearchResult, setCollapse, collapse }) => {
  const [searchActive, setSearchActive] = useState(false);
  const toggleRef = useRef(null);
  const [translate] = useTranslation();
  const menuControlRef = useRef(null);
  const loginRef = useRef(null);
  const handleToggle = () => {
    if (toggleRef?.current && menuControlRef?.current?.clientHeight > 0) {
      // eslint-disable-next-line no-unused-expressions
      toggleRef?.current?.click();
    }
  };

  useEffect(() => {
    let timeout;
    if (collapse) {
      timeout = setTimeout(() => {
        if (loginRef?.current) {
          loginRef.current.classList.add('active');
        }
      }, 250);
    }
    if (loginRef?.current && !collapse) {
      loginRef.current.classList.remove('active');
    }
    return () => clearTimeout(timeout);
  }, [collapse]);

  return (
    <Navbar
      className={`main-navbar ${home ? 'home' : ''}  ${searchEnabled ? 'has-search' : null} ${
        searchActive ? 'search-active' : ''
      } ${collapse ? 'active' : 'passive'}`}
      bg="light"
      expand="md"
      collapseOnSelect
      variant="light"
      fixed="top"
    >
      <Container>
        <div className={`logo ${collapse ? 'active' : 'passive'}`}>
          <Link
            to="/"
            className="navbar-brand"
            onClick={() => {
              analyticsMenuTap({
                screen: '/',
                element: 'logo',
                basketValue: 0,
              });
            }}
          >
            <img src={tgLogoRed} alt="Tıkla Gelsin Logo" width="100" height="56" />
          </Link>

          {searchEnabled && (
            <SearchInput
              home={home}
              data={searchData}
              parentCallBack={setSearchResult}
              onIconClick={() => setSearchActive(!searchActive)}
            />
          )}
        </div>
        <div ref={menuControlRef} className={`menu ${collapse ? 'active' : 'passive'}`}>
          <span className={`${collapse ? 'active' : 'passive'}`}>Menü</span>
          <Navbar.Toggle
            ref={toggleRef}
            aria-controls="responsive-navbar-nav"
            onClick={() => setCollapse((prev) => !prev)}
          >
            <i className="icon-hamburger-menu" />
            <i className="icon-Close-3" />
          </Navbar.Toggle>
        </div>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ml-auto d-flex align-items-md-center">
            <Nav.Item className="order-md-1 d-none d-md-block" onClick={() => handleToggle()}>
              <Link
                className="nav-link line"
                href="/a/"
                onClick={() => {
                  analyticsMenuTap({
                    screen: '/a/',
                    element: 'Hesabım',
                    basketValue: 0,
                  });
                }}
              >
                <span className="nav-icon">
                  <i className="icon-profile" />
                </span>
                <span className="nav-text">Hesabım</span>
                <span className="nav-icon-2">
                  <i className="icon-Arrow_simple_rightt" />
                </span>
              </Link>
            </Nav.Item>
            <Nav.Item onClick={() => handleToggle()}>
              <Link
                className="nav-link line"
                to="/kampanyalar"
                onClick={() => {
                  analyticsMenuTap({
                    screen: '/kampanyalar',
                    element: 'Kampanyalar',
                    basketValue: 0,
                  });
                }}
              >
                <span className="nav-icon">
                  <i className="icon-gift" />
                </span>
                <span className="nav-text">Kampanyalar</span>
                <span className="nav-icon-2">
                  <i className="icon-Arrow_simple_rightt" />
                </span>
              </Link>
            </Nav.Item>
            {/* <Nav.Item>
              <NavLink className="nav-link line" href="/">
                <span className="nav-icon notification">
                  <i className="icon-Notification" />
                  <span>1</span>
                </span>
                <span className="nav-text notification">Bildirimler</span>
                <span className="nav-icon-2">
                  <i className="icon-Arrow_simple_rightt" />
                </span>
              </NavLink>
            </Nav.Item> */}
            <Nav.Item className="d-flex align-items-center order-2">
              <div className="nav-link basket">
                <BasketButton />
              </div>
            </Nav.Item>
            <div ref={loginRef} className="login">
              <Button className="login-button rounded" block onClick={() => handleToggle()}>
                <Link to="/a/" className="login-text">
                  {translate('loginCard.login')}
                </Link>
              </Button>
            </div>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  home: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  searchData: PropTypes.any,
  setSearchResult: PropTypes.any,
  setCollapse: PropTypes.func,
  collapse: PropTypes.bool,
};

// Header.defaultProps = {}

export default Header;
