/* eslint-disable no-loop-func */
/* eslint-disable no-return-assign */
import { isEmpty } from 'lodash';

// export function updateCartSize(cartList) {
//   const cartSize = getCartListLength(cartList);
//   Navigation.mergeOptions('myCartTab', {
//     bottomTab: {
//       badge: cartSize && cartSize > 0 ? cartSize.toString() : '',
//     },
//   });
// }

export function getCartListLength(cartItemList) {
  let totalItems = 0;
  if (isEmpty(cartItemList)) return totalItems;

  for (let index = 0; index < cartItemList.length; index += 1) {
    cartItemList[index]?.cart?.map((item) => (totalItems += item?.quantity));
  }

  if (totalItems > -1) return totalItems;

  return 0;
}

export default { /* updateCartSize, */ getCartListLength };
