/**
 *
 * Loader
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie-player';
import loaderOrder from './loaderOrder.json';

function LoaderOrder({ className }) {
  return (
    <div className={className}>
      <Lottie loop animationData={loaderOrder} play style={{ width: 72, height: 72 }} />
    </div>
  );
}

LoaderOrder.propTypes = {
  className: PropTypes.string,
};

export default LoaderOrder;
