import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import LoaderOrder from '@components/LoaderOrder';
import { analtyicsStartUsingClick } from 'utils/analyticsUtils';
import Link from './Link';

function LoginCard({ className }) {
  const [translate] = useTranslation();
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 991 });

  const analyticsStartUsingButton = () => {
    analtyicsStartUsingClick();
  };

  return (
    <div className={`d-flex flex-column login-card ${className}`}>
      {isDesktop && (
        <>
          <LoaderOrder className="justify-content-start" />
          <span className="login-card-title">{translate('loginCard.title')}</span>
          <span className="login-card-description">{translate('loginCard.description')}</span>
        </>
      )}
      {isTabletOrMobile && (
        <>
          <div className="login-card-title">{translate('loginCard.title')}</div>
          <div className="login-card-description">{translate('loginCard.description')}</div>
        </>
      )}
      <Link onClick={analyticsStartUsingButton} to="/a/" className="btn btn-primary w-100 fit-content">
        {translate('loginCard.button')}
      </Link>
    </div>
  );
}

LoginCard.propTypes = {
  className: PropTypes.string,
};

export default LoginCard;
