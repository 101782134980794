/**
 *
 * cart queries
 *
 */

import { gql } from '@apollo/client';

export const GET_CART = gql`
  query GET_CART {
    cart {
      brandName
      brandId
      totalPrice: price
      minTakeawayAmount
      isClosed
      Id
      basketAmount
      serviceFeeAmount
      StoreAddress
      cart: product {
        minTakeawayAmount
        restaurantId
        totalPrice
        name
        quantity
        price
        priceWopt
        ItemId

        brand {
          name
          value
          isAvailable
          isTiklaGelsin
          isTiklaAl
          isGelAl
          BrandColor
          startHour
          endHour
          scheduledOrderEnabled
          media {
            path
            Url
          }
        }
        cart {
          productId
          name
          quantity
          price
          priceWopt
          restaurantId
          isClosed
          _id: id
          couponId
          IsServiceFee
          couponId
          isAvailable
          IsServiceFee
          status
          isDeleted
          ItemId: id
          ncrId
          options {
            id
            quantity
            name
            ncrId
            isSelected
          }
          restaurant {
            name
            val
          }
        }
      }
    }
  }
`;
